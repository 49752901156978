import React from "react";
import cn from "classnames";
import { Form } from "../Form";
import { ContactUsContainer } from "./ContactUsContainer";
import "./styles.css";
import { List } from "../List";

const ContactUs = ({ title, advantages, stages, slogan, slogonIconColor, formHeader, formBody, className }) => {
  const formProps = { ...formHeader, ...formBody };

  return (
    <div className={cn(className, "[ ContactUs ]")} id="contact-us">
      <div className="Content">
        <div className="ContactUs__content">
          {advantages && <ContactUsContainer className="ContactUs__info" title={title} advantages={advantages} />}
          {stages && (
            <List
              className="ContactUs__info"
              title={title}
              slogan={slogan}
              slogonIconColor={slogonIconColor}
              stages={stages}
            />
          )}

          <Form className="[ ContactUs__form ] [ Color_white ]" {...formProps} />
        </div>
      </div>
    </div>
  );
};
export { ContactUs };
