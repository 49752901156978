import { useCallback, useMemo, useState } from "react";

const useForm = (setNameError, setPhoneNumberError, setPhoneErrorFormat) => {
  const initialValue = {
    name: "",
    phoneNumber: "",
  };

  /*Field*/

  const [name, setName] = useState(initialValue.name);
  const handleChangeName = useCallback(e => nameHandler(e), []);
  const [phoneNumber, setPhoneNumber] = useState(initialValue.phoneNumber);
  const handleChangePhoneNumber = useCallback(e => phoneNumberHandler(e), []);

  const nameHandler = e => {
    /*clear error */
    setNameError(false);
    setName(e.target.value);
  };

  const phoneNumberHandler = e => {
    /*clear error */
    setPhoneNumberError(false);
    setPhoneErrorFormat(false);
    setPhoneNumber(e.target.value);
  };

  const [status, setStatus] = useState("initial");

  /*Form reset*/

  const initialHandle = useCallback(({ name = "", phoneNumber = "" }) => {
    setName(name);
    setPhoneNumber(phoneNumber);
  });

  /*request*/

  const api = useCallback(
    (name, phoneNumber) => {
      return fetch("/api/form", {
        method: "POST",
        body: JSON.stringify({
          Имя: name,
          "Номер телефона": phoneNumber,
        }),
        headers: {
          "content-type": "application/json",
        },
      });
    },
    [name, phoneNumber]
  );

  /*submit data*/
  const handleSubmit = useCallback(
    async _ => {
      if (status === "success") {
        return setStatus("initial");
      }
      setStatus("loading");
      try {
        const response = await api(name, phoneNumber);

        if (!response.ok) {
          return setStatus("failure");
        }
        setStatus("success");
        initialHandle(initialValue);
      } catch (err) {
        return setStatus("failure");
      }
    },

    [name, phoneNumber, status]
  );

  /* validate form */

  const validateBeforeSubmitHandler = e => {
    let error = false;
    e.preventDefault();

    /*empty */
    if (name === "" || undefined) {
      setNameError(true);
      error = true;
    }
    if (phoneNumber === "" || undefined) {
      setPhoneNumberError(true);
      error = true;
    }
    /*format*/

    if (phoneNumber.lastIndexOf("_") !== -1) {
      setPhoneErrorFormat(true);
      error = true;
    }

    if (!error) {
      handleSubmit();
    }
  };

  /* memo data */

  const result = useMemo(
    () => ({
      nameProps: { value: name, onChange: handleChangeName },
      phoneNumberProps: { value: phoneNumber, onChange: handleChangePhoneNumber },
      status,
      onSubmit: validateBeforeSubmitHandler,
    }),
    [name, handleChangeName, phoneNumber, handleChangePhoneNumber, status, handleSubmit]
  );
  return result;
};

export { useForm };
