import cn from "classnames";
import React from "react";
import { ArrowRight, Bee } from "./defaultIcons";
import "./styles.css";

const Button = ({ className, icon, stretch, outlined_black, outlined_yellow, black, yellow, grey, children }) => {
  const buttonProperty = cn({
    outlined_black,
    outlined_yellow,
    black,
    yellow,
    grey,
  });
  const stretchButton = cn({
    stretch,
  });

  let buttonIcon = false;

  if (black) {
    buttonIcon = <Bee />;
  }

  if (outlined_black) {
    buttonIcon = <ArrowRight />;
  }

  return (
    <button className={cn(className, `[ Button Button_${buttonProperty} Button_${stretchButton} ]`)}>
      {children}
      {icon ? <i className="Button_Icon">{buttonIcon}</i> : null}
    </button>
  );
};

export { Button };
