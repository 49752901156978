import React from "react";

const Mail = props => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 2c-4.41 0-8 3.591-8 8 0 4.41 3.59 8 8 8h3.2a.798.798 0 00.75-1.109.8.8 0 00-.75-.49H10A6.387 6.387 0 013.6 10c0-3.545 2.855-6.4 6.4-6.4 3.544 0 6.4 2.855 6.4 6.4v1.2c0 .672-.527 1.2-1.2 1.2-.673 0-1.2-.528-1.2-1.2V10c0-.03-.002-.06-.005-.09A4.01 4.01 0 0010 6.001c-2.2 0-4 1.8-4 4 0 2.199 1.8 4 4 4 1.13 0 2.15-.482 2.88-1.243A2.805 2.805 0 0015.2 14c1.537 0 2.8-1.263 2.8-2.8V10c0-4.409-3.59-8-8-8zm0 5.6c1.335 0 2.4 1.065 2.4 2.4s-1.065 2.4-2.4 2.4A2.388 2.388 0 017.6 10c0-1.335 1.065-2.4 2.4-2.4z"
        fill="#B3B3B3"
      />
    </svg>
  );
};

export { Mail };
