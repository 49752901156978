import cn from "classnames";
import React, { useState, useEffect } from "react";
import { PhoneInput } from "./PhoneInput/PhoneInput";
import { Button } from "../Button";
import { Spinner } from "../Spinner";
import "./styles.css";
import { Success } from "./Success";
import { useForm } from "./useForm";

const Form = ({
  title,
  text,
  policy,
  buttonLabel,
  successMessage,
  successButtonLabel,
  errorMessage,
  errorButtonLabel,
  className,
}) => {
  const [nameError, setNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneErrorFormat, setPhoneErrorFormat] = useState(false);
  const { nameProps, phoneNumberProps, status, onSubmit } = useForm(
    setNameError,
    setPhoneNumberError,
    setPhoneErrorFormat
  );

  const nameInputStatusClassname = cn({
    Form__input: true,
    Form__inputErrorEmpty: nameError,
  });

  const phoneInputStatusClassname = cn({
    Form__input: true,
    Form__inputErrorEmpty: phoneNumberError,
    Form__inputErrorFormat: phoneErrorFormat,
  });

  return (
    <div className={cn(className, "[ Form ]")}>
      <form onSubmit={onSubmit}>
        <div className="Form__info">
          <h3 className="Font_headerSerif">{title}</h3>
          <p className="[ Form__text ] [ Font_regularText ]">{text}</p>
        </div>
        <div className="Form__control">
          {(status === "initial" || status === "loading" || status === "failure") && (
            <>
              <input
                className={cn(`[ ${nameInputStatusClassname} ]`, "[ Font_regularText Color_black ]")}
                type="text"
                name="name"
                placeholder="Имя"
                autoComplete="off"
                type="text"
                aria-label="Имя"
                {...nameProps}
              />
              {nameError && <p className="Input_errorText">Необходимо заполнить это поле</p>}
              <PhoneInput
                className={cn(`[ ${phoneInputStatusClassname} ]`, "[ Font_regularText Color_black ]")}
                {...phoneNumberProps}
                phoneNumberError={phoneNumberError}
                phoneErrorFormat={phoneErrorFormat}
              />
              <p className="[ Form__policy ] [ Font_smallText  Color_lightGrey ]">{policy}</p>
            </>
          )}

          {status === "success" && (
            <div className="Form__success">
              <Success />
              <p className="Font_headerMobile Color_yellow">{successMessage}</p>
            </div>
          )}

          <Button
            className="Form__order"
            stretch
            yellow={status === "initial" || status === "loading"}
            grey={status === "success" || status === "failure"}
          >
            {status === "initial" && buttonLabel}
            {status === "loading" && <Spinner />}
            {status === "success" && successButtonLabel}
            {status === "failure" && errorButtonLabel}
          </Button>

          {status === "failure" && (
            <p className="[ Form__errorMessage ] [ Font_smallText Color_red ]">{errorMessage}</p>
          )}
        </div>
      </form>
    </div>
  );
};

export { Form };
