import React, { useEffect, useState } from "react";
import { FooterContacts } from "./FooterContacts";
import { FooterMap } from "./FooterMap/FooterMap";
import "./styles.css";

const MAP_URL =
  "https://yandex.ru/map-widget/v1/?um=constructor%3Acbeea54b24ffc86eff8dd5b52eed7929f348acf8d7275b0ef46a0714a511a396&amp;source=constructor";

const Footer = ({ title, phoneNumbers, email, instagramLabel, instagramLink, address, copyright }) => {
  const [mapSrc, setMapSrc] = useState("");
  useEffect(() => {
    window.onload = function() {
      setMapSrc(MAP_URL);
    };

    setTimeout(() => setMapSrc(MAP_URL), 15000);
  }, []);

  return (
    <div id="footer" className="[ Footer ] [ Color_white ]">
      <div className="[ Footer__content ] [ Content ]">
        <FooterContacts
          className="Footer__contacts"
          title={title}
          phoneNumbers={phoneNumbers}
          email={email}
          instagramLabel={instagramLabel}
          instagramLink={instagramLink}
          address={address}
        />

        <FooterMap className="Footer__map" mapSrc={mapSrc} copyright={copyright} />
      </div>
    </div>
  );
};

export { Footer };
