import cn from "classnames";
import React from "react";
import { HexagonViolet } from "./icons/HexagonViolet";
import { HexagonYellow } from "./icons/HexagonYellow";
import "./styles.css";

const ContactUsContainer = ({ className, title, advantages }) => {
  const classNameAdvantage = cn({
    ContactUsContainer__advantage: true,
    ContactUsContainer__advantage_withTitle: title,
    ContactUsContainer__advantage_withoutTitle: !title,
  });
  return (
    <div className={cn(className, " [ ContactUsContainer ]")}>
      {title && <h2 className="[ ContactUsContainer__title ] [ Font_headerSerif Color_black ]">{title}</h2>}
      <div className="ContactUsContainer__advantages">
        {advantages.map(({ title, text }, index) => (
          <div className={classNameAdvantage} key={title}>
            {index % 2 === 0 ? (
              <HexagonViolet className=" [ ContactUsContainer__icon ]" />
            ) : (
              <HexagonYellow className=" [ ContactUsContainer__icon ] " />
            )}
            <h3 className=" [ ContactUsContainer__advantageTitle ] [ Font_smallHeader  Color_black]">{title}</h3>
            <p className=" [ ContactUsContainer__advantageText ] [ Font_regularText Color_darkGrey ]">{text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export { ContactUsContainer };
