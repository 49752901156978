import cn from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Headroom from "react-headroom";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { Close } from "../../../icons/Close";
import { Instagram, Mail, Point } from "../../../icons/footer";
import { Menu } from "../../../icons/Menu";
import { Phone } from "../../../icons/Phone";
import { DropDownMenu, DropDownMenuDivider, DropDownMenuLink } from "../../DropDownMenu";
import "./styles.css";

const MobileHeader = ({ className, phoneNumber, email, address, instagramLabel, instagramLink }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  let menu;

  const handleToggleMenu = useCallback(() => {
    if (isOpenMenu) {
      menu.removeEventListener("scroll", handleBodyScroll);
    }
    document.body.style.height = "auto";
    document.body.style.overflow = "auto";
    setIsOpenMenu(!isOpenMenu);
  }, [isOpenMenu]);

  const handleCloseMenu = useCallback(() => setIsOpenMenu(false), []);

  useEffect(() => {
    menu = document.querySelector(".MobileHeader__menu");
    menu.addEventListener("scroll", handleBodyScroll);
  });

  const handleBodyScroll = useCallback(() => {
    document.body.style.height = "100%";
    document.body.style.overflow = "hidden";
  });

  const mobileHeader = useRef(null);
  useOnClickOutside(mobileHeader, handleCloseMenu);

  return (
    <Headroom className={cn(className, "MobileHeader", { MobileHeader_active: isOpenMenu })}>
      <div className="[ MobileHeader__container ] [ Content ]" ref={mobileHeader}>
        <button className="MobileHeader__icon" onClick={handleToggleMenu}>
          {!isOpenMenu ? <Menu /> : <Close />}
        </button>

        <a className="MobileHeader__icon" href={`tel:${phoneNumber}`}>
          <Phone />
        </a>

        <DropDownMenu className="MobileHeader__menu" isOpen={isOpenMenu} onClose={handleCloseMenu}>
          <DropDownMenuLink href="/" onClick={handleCloseMenu}>
            Главная
          </DropDownMenuLink>
          <DropDownMenuLink href="/classic-syrups" onClick={handleCloseMenu}>
            Классические сиропы
          </DropDownMenuLink>
          <DropDownMenuLink href="/professional-syrups" onClick={handleCloseMenu}>
            Профессиональная серия сиропов
          </DropDownMenuLink>
          <DropDownMenuLink href="/packaged-honey" onClick={handleCloseMenu}>
            Мёд натуральный в стик-пакетах
          </DropDownMenuLink>
          <DropDownMenuLink href="/honey" onClick={handleCloseMenu}>
            Мёд цветочный
          </DropDownMenuLink>
          <DropDownMenuLink href="/own-brand" onClick={handleCloseMenu}>
            СТМ
          </DropDownMenuLink>
          <DropDownMenuLink href="/partners" onClick={handleCloseMenu}>
            Сотрудничество
          </DropDownMenuLink>
          <DropDownMenuLink href="/about-us" onClick={handleCloseMenu}>
            О компании
          </DropDownMenuLink>
          <DropDownMenuLink href="/#footer" onClick={handleCloseMenu}>
            Контакты
          </DropDownMenuLink>

          <DropDownMenuDivider />

          <div className="[ MobileHeader__contacts ] [ Font_smallText Color_black ]">
            <p className="MobileHeader__contact">
              <Phone className="MobileHeader__contactIcon" />
              <a className="[ MobileHeader__link ] [ Color_black ]" href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </a>
            </p>
            <p className="MobileHeader__contact">
              <Mail className="MobileHeader__contactIcon" />
              <span>{email}</span>
            </p>
            <p className="MobileHeader__contact">
              <Instagram className="MobileHeader__contactIcon" />
              <a className="[ MobileHeader__link ] [ Color_black ]" href={instagramLink} target="_blank">
                {instagramLabel}
              </a>
            </p>
            <p className="MobileHeader__contact MobileHeader__contact_address">
              <Point className="MobileHeader__contactIcon" />
              <span>{address}</span>
            </p>
          </div>
        </DropDownMenu>
      </div>
    </Headroom>
  );
};

export { MobileHeader };
