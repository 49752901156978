import cn from "classnames";
import React from "react";
import "./styles.css";

const TopHeader = ({ className, address, email, phoneNumber }) => {
  return (
    <div className={cn(className, "[ TopHeader ] [ Font_smallText Color_lightGrey ]")}>
      <div className="[ TopHeader__content ] [ Content ]">
        <p>{address}</p>

        <div className="TopHeader__contacts">
          <p>{email}</p>
          <a className="Color_lightGrey" href={`tel:${phoneNumber}`}>
            {phoneNumber}
          </a>
        </div>
      </div>
    </div>
  );
};

export { TopHeader };
