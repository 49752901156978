import cn from "classnames";
import { Link } from "gatsby";
import React from "react";

const DropDownMenuLink = ({ href, disabled, onClick, children }) => {
  return (
    <li className="[ DropDownMenuLink ]" tabIndex={-1}>
      {href ? (
        <Link
          className={cn(
            {
              DropDownMenuLink__link: true,
            },
            {
              Font_mediumText: true,
              Color_darkGrey: true,
            }
          )}
          activeClassName="DropDownMenuLink__link_active"
          to={href}
          role="menuitem"
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </Link>
      ) : (
        <button
          className="[ DropDownMenuLink__link DropDownMenuLink__link_button ] [ Font_mediumText Color_darkGrey ]"
          role="menuitem"
          onClick={onClick}
        >
          Связаться с нами
        </button>
      )}
    </li>
  );
};

export { DropDownMenuLink };
