import React from "react";

const ExpandArrow = ({ ...props }) => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.953 6.333l-1 1L8 11.38l4.047-4.047-1-1L8 9.38 4.953 6.333z" fill="#7C7C7D" />
    </svg>
  );
};

export { ExpandArrow };
