import React from "react";

const Instagram = props => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.444 2A4.45 4.45 0 002 6.444v7.112A4.45 4.45 0 006.444 18h7.112A4.45 4.45 0 0018 13.556V6.444A4.45 4.45 0 0013.556 2H6.444zm0 1.778h7.112a2.67 2.67 0 012.666 2.666v7.112a2.67 2.67 0 01-2.666 2.666H6.444a2.67 2.67 0 01-2.666-2.666V6.444a2.67 2.67 0 012.666-2.666zm8 .889a.889.889 0 100 1.777.889.889 0 000-1.777zM10 5.556A4.45 4.45 0 005.556 10 4.45 4.45 0 0010 14.444 4.45 4.45 0 0014.444 10 4.45 4.45 0 0010 5.556zm0 1.777A2.67 2.67 0 0112.667 10 2.67 2.67 0 0110 12.667 2.67 2.67 0 017.333 10 2.67 2.67 0 0110 7.333z"
        fill="#B3B3B3"
      />
    </svg>
  );
};

export { Instagram };
