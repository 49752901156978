import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { ExpandArrow } from "../../../icons/ExpandArrow";
import { DropDownMenu, DropDownMenuLink } from "../../DropDownMenu";
import "./styles.css";

const ProductsMenu = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const handleToggleMenu = useCallback(() => setIsOpenMenu(!isOpenMenu), [isOpenMenu]);
  const handleCloseMenu = useCallback(() => setIsOpenMenu(false), []);

  const menu = useRef(null);
  useOnClickOutside(menu, handleCloseMenu);

  useEffect(() => {
    window.addEventListener("scroll", handleCloseMenu, { passive: true });
  });

  return (
    <li className="ProductsMenu" ref={menu}>
      <button className="[ ProductsMenu__button ] [ Font_mediumText Color_darkGrey ]" onClick={handleToggleMenu}>
        Продукция
        <ExpandArrow />
      </button>

      <DropDownMenu className="ProductsMenu__menu" isOpen={isOpenMenu} onClose={handleCloseMenu}>
        <DropDownMenuLink href="/classic-syrups" onClick={handleCloseMenu}>
          Классические сиропы
        </DropDownMenuLink>
        <DropDownMenuLink href="/professional-syrups" onClick={handleCloseMenu}>
          Профессиональная серия сиропов
        </DropDownMenuLink>
        <DropDownMenuLink href="/packaged-honey" onClick={handleCloseMenu}>
          Мёд натуральный в стик-пакетах
        </DropDownMenuLink>
        <DropDownMenuLink href="/honey" onClick={handleCloseMenu}>
          Мёд цветочный
        </DropDownMenuLink>
      </DropDownMenu>
    </li>
  );
};

export { ProductsMenu };
