import React from "react";

const Phone = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18.424 14.902l-2.245-.258a1.773 1.773 0 00-1.46.507L13.07 16.8a13.346 13.346 0 01-3.4-2.469 13.345 13.345 0 01-2.47-3.4L8.85 9.282c.383-.383.57-.918.507-1.461l-.258-2.244A1.783 1.783 0 007.326 4H5.785c-1.007 0-1.844.837-1.782 1.844a15.084 15.084 0 004.403 9.75 15.084 15.084 0 009.75 4.403c1.007.062 1.844-.775 1.844-1.782v-1.54c0-.909-.677-1.666-1.576-1.773z" />
    </svg>
  );
};

export { Phone };
