import React from "react";

const Phone = props => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.181 1.667c-.8-.005-1.6.068-2.389.218A12.414 12.414 0 00.96 7.1c-.27.402-.065.826.197 1.004l2.105 1.414a.725.725 0 00.996-.182c.263-.372.604-.832.86-1.097.275-.287.651-.603.922-.82a.734.734 0 00.259-.713L5.89 4.697a11.09 11.09 0 013.176-1.146c.01-.001.019-.004.029-.006l.03-.007a11.086 11.086 0 013.375-.136l.421 2.007a.735.735 0 00.51.561c.332.1.798.25 1.162.412.336.148.828.447 1.21.692a.718.718 0 00.99-.207l1.415-2.105c.178-.261.209-.732-.195-1.002a12.38 12.38 0 00-6.832-2.093zm-3.681 5v1.666l-3.54 2.832a1.666 1.666 0 00-.627 1.303v4.199H2.5v1.666h15v-1.666h-.834v-4.2c0-.506-.23-.985-.626-1.302L12.5 8.333V6.667h-1.667v1.666H9.167V6.667H7.5zm0 5a.834.834 0 110 1.667.834.834 0 010-1.667zm2.5 0a.834.834 0 110 1.667.834.834 0 010-1.667zm2.5 0a.834.834 0 110 1.667.834.834 0 010-1.667zm-5 2.5a.834.834 0 110 1.667.834.834 0 010-1.667zm2.5 0a.834.834 0 110 1.667.834.834 0 010-1.667zm2.5 0a.834.834 0 110 1.667.834.834 0 010-1.667z"
        fill="#B3B3B3"
      />
    </svg>
  );
};

export { Phone };
