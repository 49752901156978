import React from "react";

export const HexagonYellow = props => {
  return (
    <svg {...props} width="50" height="44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.676 0H16.269a8 8 0 00-6.907 3.964L1.326 17.716a8 8 0 00-.047 7.99l8.099 14.247A8 8 0 0016.333 44h17.279a8 8 0 006.946-4.03L48.7 25.72a8 8 0 00-.048-8.021L40.573 3.948A8 8 0 0033.676 0z"
        fill="url(#paint1_linear)"
        opacity=".5"
      />
      <defs>
        <linearGradient id="paint1_linear" x1="30.357" y1="3.029" x2="30.357" y2="60.58" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FABF01" />
          <stop offset="1" stopColor="#FABF01" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
