import React, { useState } from "react";
import NumberFormat from "react-number-format";

export const PhoneInput = ({ className, value, onChange, phoneNumberError, phoneErrorFormat }) => {
  const [isActivePhoneNumberInput, setIsActivePhoneNumberInput] = useState("");
  const showMask = () => {
    setIsActivePhoneNumberInput(true);
  };
  const hideMask = () => {
    setIsActivePhoneNumberInput(false);
  };

  return (
    <>
      <NumberFormat
        className={className}
        value={value}
        format="+375 (##) ###-##-##"
        placeholder="Номер телефона"
        mask="_"
        allowEmptyFormatting={isActivePhoneNumberInput}
        onChange={onChange}
        onFocus={showMask}
        onBlur={hideMask}
      />
      {phoneNumberError && <p className="Input_errorText">Проверьте введенный номер телефона</p>}
      {phoneErrorFormat && <p className="Input_errorText">Проверьте введенный номер телефона</p>}
    </>
  );
};
