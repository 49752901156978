import React from "react";

const Menu = props => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.373 4.8a.901.901 0 00-.371.074.955.955 0 00-.316.222c-.09.096-.162.21-.211.335a1.094 1.094 0 000 .794c.049.126.12.24.211.336.09.096.198.171.316.222.118.05.244.076.37.074h17.256A.901.901 0 0021 6.783a.956.956 0 00.316-.222c.09-.096.162-.21.211-.336a1.095 1.095 0 000-.794 1.036 1.036 0 00-.211-.335.956.956 0 00-.316-.222.901.901 0 00-.37-.074H3.372zm0 6.171a.902.902 0 00-.371.074.957.957 0 00-.316.222c-.09.096-.162.21-.211.336a1.094 1.094 0 000 .794c.049.126.12.24.211.335.09.096.198.171.316.222.118.051.244.076.37.074h17.256a.902.902 0 00.371-.074.956.956 0 00.316-.222c.09-.095.162-.21.211-.335a1.094 1.094 0 000-.794 1.036 1.036 0 00-.211-.336.957.957 0 00-.316-.222.902.902 0 00-.37-.074H3.372zm0 6.172a.9.9 0 00-.371.074.955.955 0 00-.316.222c-.09.095-.162.21-.211.335a1.094 1.094 0 000 .794c.049.126.12.24.211.336.09.095.198.17.316.222.118.05.244.076.37.074h17.256a.902.902 0 00.371-.074.956.956 0 00.316-.222c.09-.096.162-.21.211-.336a1.095 1.095 0 000-.794 1.038 1.038 0 00-.211-.335.955.955 0 00-.316-.222.9.9 0 00-.37-.074H3.372z"
        fill="#161614"
      />
    </svg>
  );
};

export { Menu };
