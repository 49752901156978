import React from "react";

const Close = (...props) => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.878 5a.874.874 0 00-.61 1.5l5.499 5.498-5.498 5.498a.873.873 0 101.235 1.235l5.498-5.498 5.498 5.498a.874.874 0 101.235-1.235l-5.498-5.498L18.735 6.5A.873.873 0 1017.5 5.265l-5.498 5.498-5.498-5.498A.874.874 0 005.878 5z"
        fill="#000"
      />
    </svg>
  );
};

export { Close };
