import React from "react";

const Point = props => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 2C6.686 2 4 4.58 4 7.761c0 3.264 3.775 8.082 5.337 9.934a.874.874 0 001.326 0C12.225 15.843 16 11.025 16 7.761c0-3.182-2.686-5.76-6-5.76zm0 7.818c-1.184 0-2.143-.92-2.143-2.057S8.817 5.704 10 5.704c1.184 0 2.143.92 2.143 2.057S11.183 9.818 10 9.818z"
        fill="#B3B3B3"
      />
    </svg>
  );
};

export { Point };
