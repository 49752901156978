import cn from "classnames";
import React from "react";
import { Skeleton } from "../../Skeleton";
import "./styles.css";

const FooterMap = ({ className, mapSrc, copyright }) => {
  return (
    <div className={cn(className, "FooterMap")}>
      <div className="FooterMap__map">
        {mapSrc ? <iframe width="100%" height="100%" title="Карта" src={mapSrc} frameBorder="0" /> : <Skeleton />}
      </div>
      <div>
        <p>{copyright}</p>
        <p>УНП 100262213</p>
        <p className="FooterMap__copyrightDeveloper">Разработано YellowSquare</p>
      </div>
    </div>
  );
};

export { FooterMap };
