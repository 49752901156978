import React from "react";
import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";
import "./styles.css";
import { TopHeader } from "./TopHeader";

const Header = ({ logo, address, email, phoneNumber, instagramLabel, instagramLink }) => {
  return (
    <header className="Header">
      <TopHeader className="Header__topHeader" address={address} email={email} phoneNumber={phoneNumber} />

      <DesktopHeader className="Header__desktop" logo={logo} />
      <MobileHeader
        className="Header__mobile"
        phoneNumber={phoneNumber}
        email={email}
        address={address}
        instagramLabel={instagramLabel}
        instagramLink={instagramLink}
      />
    </header>
  );
};

export { Header };
