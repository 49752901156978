import cn from "classnames";
import React from "react";
import { HexagonViolet, HexagonYellow } from "./../HomeContactUs/icons";
import "./styles.css";
export const List = ({ className, stages, slogan, title, titleBig, sloganIconColor = "violet" }) => {
  return (
    <div className={cn(className, "[ List ]")}>
      {title && <h2 className="[ List__title ] [ Font_headerSerif Color_black ]">{title}</h2>}
      {titleBig && (
        <h2 className="[ List__title ] [ Font_bigHeaderSerif sm:Font_headerSerif Color_black ]">{titleBig}</h2>
      )}
      <div className="List_wrapper">
        <div className=" [ List__slogan ] [ Font_smallHeader Color_black ]">{slogan}</div>
        {sloganIconColor === "violet" && <HexagonViolet className="List__sloganIcon" />}
        {sloganIconColor === "yellow" && <HexagonYellow className="List__sloganIcon" />}
        <ul className="List__items [ Font_regularText Color_darkGrey ]">
          {stages.map(stage => (
            <div key={stage} className="List__item">
              •<li className="List__itemText">{stage}</li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};
