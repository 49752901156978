import React from "react";

const ArrowRight = ({ ...props }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.50024 10C2.50024 10.46 2.87358 10.8333 3.33358 10.8333H14.2255L11.0061 14.0527C10.6803 14.3786 10.6803 14.9061 11.0061 15.2311L11.0777 15.3027C11.4036 15.6286 11.9311 15.6286 12.2561 15.3027L16.9696 10.5892C17.2955 10.2634 17.2955 9.73582 16.9696 9.41082L12.2561 4.69728C11.9303 4.37144 11.4027 4.37144 11.0777 4.69728L11.0061 4.76889C10.6803 5.09472 10.6803 5.62227 11.0061 5.94728L14.2255 9.16668H3.33358C2.87358 9.16668 2.50024 9.54001 2.50024 10Z"
        fill="#161614"
      />
    </svg>
  );
};

export { ArrowRight };
