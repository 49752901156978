import cn from "classnames";
import React from "react";
import { Instagram, Mail, Phone, Point } from "../../../icons/footer";
import "./styles.css";

const FooterContacts = ({ className, title, phoneNumbers, email, instagramLabel, instagramLink, address }) => {
  return (
    <div className={cn(className, "FooterContacts")}>
      <h2 className="Font_headerSerif">{title}</h2>
      <div className="FooterContacts__contacts">
        <div className="FooterContacts__phoneNumbersList">
          {phoneNumbers.map(({ title: phoneNumberTitle, phoneNumber }, index) => (
            <div className="[ FooterContacts__phoneNumbersListItem ] [ Font_regularText ]" key={phoneNumberTitle}>
              <p className="[ FooterContacts__label ] [ Color_darkGrey ]">{phoneNumberTitle}</p>
              <p className="FooterContacts__contact">
                <Phone className="FooterContacts__icon" />
                <a className="[ FooterContacts__link ] [ Color_white ]" href={`tel:${phoneNumber}`}>
                  {phoneNumber}
                </a>
              </p>
            </div>
          ))}
          <p className="FooterContacts__contact FooterContacts__contact_withoutLabel">
            <Mail className="FooterContacts__icon" />
            <span>{email}</span>
          </p>
          <p className="FooterContacts__contact FooterContacts__contact_withoutLabel">
            <Instagram className="FooterContacts__icon" />
            <a className="[ FooterContacts__link ] [ Color_white ]" href={instagramLink} target="_blank">
              {instagramLabel}
            </a>
          </p>
        </div>

        <p className="FooterContacts__contact FooterContacts__contact_multiString">
          <Point className="FooterContacts__icon" />
          <span>{address}</span>
        </p>
      </div>
    </div>
  );
};

export { FooterContacts };
