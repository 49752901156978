import { Link } from "gatsby";
import React from "react";
import Headroom from "react-headroom";
import { Image } from "../../Image";
import { ProductsMenu } from "../ProductsMenu";
import "./styles.css";

const DesktopHeader = ({ className, logo }) => {
  return (
    <Headroom className={className} pinStart={32}>
      <div className="[ DesktopHeader__container ] [ Content ]">
        <Link to="/">
          <Image image={logo} alt="" />
        </Link>

        <nav>
          <ul className="DesktopHeader__links">
            <li>
              <Link className="[ DesktopHeader__link ] [ Font_mediumText Color_darkGrey ]" to="/">
                Главная
              </Link>
            </li>
            <li>
              <ProductsMenu />
            </li>
            <li>
              <Link className="[ DesktopHeader__link ] [ Font_mediumText Color_darkGrey ]" to="/own-brand">
                СТМ
              </Link>
            </li>
            <li>
              <Link className="[ DesktopHeader__link ] [ Font_mediumText Color_darkGrey ]" to="/partners">
                Сотрудничество
              </Link>
            </li>
            <li>
              <Link className="[ DesktopHeader__link ] [ Font_mediumText Color_darkGrey ]" to="/about-us">
                О компании
              </Link>
            </li>
            <li>
              <Link className="[ DesktopHeader__link ] [ Font_mediumText Color_darkGrey ]" to="/#footer">
                Контакты
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </Headroom>
  );
};

export { DesktopHeader };
