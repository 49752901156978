import { graphql } from "gatsby";
import React from "react";
import { Footer } from "../Footer";
import { Header } from "../Header/Header";
import "./styles.css";

const Layout = ({
  logo,
  address,
  email,
  instagramLabel,
  instagramLink,
  phoneNumber,
  footerTitle,
  footerPhoneNumbers,
  copyright,
  children,
}) => {
  return (
    <div className="Layout">
      <Header
        logo={logo}
        address={address}
        email={email}
        phoneNumber={phoneNumber}
        instagramLabel={instagramLabel}
        instagramLink={instagramLink}
      />

      <div className="Layout__content">{children}</div>

      <Footer
        title={footerTitle}
        phoneNumbers={footerPhoneNumbers}
        email={email}
        instagramLabel={instagramLabel}
        instagramLink={instagramLink}
        address={address}
        copyright={copyright}
      />
    </div>
  );
};

Layout.getLivePreviewData = ({ data, getAsset }) => ({
  ...data,
});

const layoutQuery = graphql`
  fragment LayoutFragment on MarkdownRemarkFrontmatter {
    logo {
      childImageSharp {
        fixed(width: 136) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    address
    email
    instagramLabel
    instagramLink
    phoneNumber

    footerTitle
    footerPhoneNumbers {
      title
      phoneNumber
    }
    copyright
  }
`;

export { Layout, layoutQuery };
