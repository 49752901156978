import React from "react";

const Success = () => {
  return (
    <svg width="78" height="72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_di)">
        <path
          d="M47.603 3H28.325a16 16 0 00-13.89 8.06L5.477 26.725a16 16 0 00-.093 15.718l9.083 16.332A16 16 0 0028.451 67h19.026a16 16 0 0013.965-8.192l9.131-16.332a16 16 0 00-.093-15.781l-9.005-15.668A16 16 0 0047.603 3z"
          fill="#F3C44A"
        />
      </g>
      <path
        d="M38 22.2c-7.069 0-12.8 5.731-12.8 12.8 0 7.069 5.731 12.8 12.8 12.8 7.069 0 12.8-5.731 12.8-12.8 0-7.069-5.731-12.8-12.8-12.8zm7.154 10.354l-8.064 8.064a1.066 1.066 0 01-1.508 0L31.9 36.935a1.065 1.065 0 111.508-1.508l2.93 2.929 7.31-7.31a1.066 1.066 0 111.507 1.508z"
        fill="#161614"
      />
      <defs>
        <filter
          id="filter0_di"
          x=".368"
          y="0"
          width="77.24"
          height="72"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.48 0" />
          <feBlend in2="shape" result="effect2_innerShadow" />
        </filter>
      </defs>
    </svg>
  );
};

export { Success };
